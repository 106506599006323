<template>
  <div class="i_footer">
    <div class="foot-top">
      <div v-show="friendLinkList && friendLinkList.length" class="friend_link">
        <ul class="clearfix">
          <div class="link_one">
            {{ $t('link') }}
          </div>

          <!--          <div class="link-container">-->
          <!--            <li v-for="(item, index) in friendLinkList" v-show="item.form===2" :key="index" class="link-item">-->
          <!--              <img v-show="item.form===2" :src="item.image" alt="icon" class="icon">-->
          <!--            </li>-->
          <!--          </div>-->
          <div class="link-container">
            <li v-for="(item, index) in filterLink(friendLinkList,'yl')" v-show="item.form===1" :key="index" class="link-item-text">
              <a  :href="item.url" target="blank">{{ item.name }}</a>
            </li>
          </div>

          <!-- <li v-for="(item, index) in friendLinkList" :key="index"><a :href="item.url" target="blank">{{ item.name }}</a></li> -->
        </ul>
      </div>

      <div class="contact-way">
        <div class="content">
          <div class="contact-way-imgs">
            <img v-for="(item, index) in filterLink(friendLinkList,'ewm')"  :key="index" class="img" :src="item.image" alt="">
            <!--            <img class="img" src="../../images/testCode.png" />-->
            <!--            <img class="img" src="../../images/testCode.png" />-->
            <!--            <img class="img" src="../../images/testCode.png" />-->
            <!--            <img class="img" src="../../images/testCode.png" />-->
            <!--            <img class="img" src="../../images/testCode.png" />-->
            <!--            <img class="img" src="../../images/testCode.png" />-->
            <!--            <img class="img" src="../../images/testCode.png" />-->
            <!--            <img class="img" src="../../images/testCode.png" />-->
            <!--            <img class="img" src="../../images/testCode.png" />-->
          </div>
          <div class="tel">
            <i class="el-icon-phone-outline tel-label" style="margin-right: 0.5vw;"></i>
            <!-- 文字链接+联系我们 -->
            <span v-for="(item, index) in filterLink(friendLinkList,'lxwm')" :key="index" class="tel-number">{{ item.name }}</span>
          </div>

          <!-- <div class="tel">
            <div class="tel-label">{{ $t('contactWay') }}</div> -->
            <!-- 文字链接+联系我们 -->
          <!--  <div v-for="(item, index) in filterLink(friendLinkList,'lxwm')" :key="index" class="tel-number">{{ item.name }}</div>
          </div> -->
        </div>
      </div>
    </div>

    <Bottom class="bottom" :text-color="'#fff !important'" style="border-top: 0.5px solid rgba(255, 255, 255, 0.1);width: 90%;margin: auto;" />
  </div>
</template>
<script>
import {
  linkList
} from '~/api/main.js'
import Bottom from '@/components/common/Bottom'

export default {
  name: 'Footer',
  components: {
    Bottom
  },
  data() {
    return {
      friendLinkList: []
    }
  },
  mounted() {
    linkList().then((res) => {
      this.friendLinkList = res?.records || []
      console.log('friendLinkList', this.friendLinkList)
    })
  },
  methods: {
    filterLink(friendLinkList, type) {
      if (type === 'lxwm') {
        return friendLinkList.filter(item => item.form === 1 && item.type === 2)
      } else if (type === 'ewm') {
        return friendLinkList.filter(item => item.form === 2)
      } else if (type === 'yl') {
        return friendLinkList.filter(item => item.form === 1 && item.type === 1)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @media (max-width: 768px) {
    .bottom{
      padding: 3vw;
    }
    .i_footer {

      padding-top: 1vw !important;

      .foot-top {
        display: block !important; // 改为块级布局
        width: 90vw !important; // 可以根据需要调整宽度
        margin-bottom: 3vw !important;
      }

      .friend_link {
        padding: 0.6vw 1vw !important;

        .link-container {
          // flex-direction: column; // 设置为纵向布局
        }

        .link_one {
          font-size: 4vw !important;
          margin-right: 0 !important; // 移除右侧边距
          margin-bottom: 3vw !important; // 添加底部边距
        }
        .link-item {
          font-size: 3.5vw !important;
          margin-bottom: 20px !important;
           /* 垂直居中对齐 */
           margin-right: 10px !important;
           /* 右侧间距 */
          flex: 0 0 calc(33.33% - 10px) !important;
        }
        .link-item-text {
          font-size: 3.5vw !important;
          margin-bottom: 20px !important;
           /* 垂直居中对齐 */
           margin-right: 10px !important;
           /* 右侧间距 */
          flex: 0 0 calc(50% - 10px) !important;
        }
        .icon {
          width: 70px !important;
          /* 设置图标的宽度 */

          object-fit: contain;
          /* 设置图标的高度 */
          margin-right: 10px !important;
          /* 图标和文本之间的间隔 */
        }

      }

      .contact-way {
        .content {
          float: none !important; // 取消浮动，使用正常的文档流
          width: 100%; // 确保宽度100%
        }
        .tel {
          margin-left: 1vw !important;
          .tel-label {
            font-size: 4.5vw !important;
            // margin-bottom: 3vw !important; // 添加底部边距
          }
          .tel-number {
            font-size: 4.5vw !important;
          }
        }

        .contact-way-imgs {
          display: flex;
          flex: 0 0 calc(33.33% - 20px);
          //justify-content: center;
          flex-wrap: wrap;
          // flex-direction: column; // 设置为纵向布局

          .img {
            // width: 33.33% !important;
            // height: auto !important;
            margin-left: 0 !important; // 移除左侧边距
            padding: 1vw;
            margin-bottom: 3vw !important; // 添加底部边距
          }
        }
      }
    }
  }
  // @media (max-width: 768px) {
  //   .i_footer {
  //     padding-top: 1vw !important;

  //     .friend_link {
  //       width: 90vw !important;
  //       padding: 0.6vw 1vw !important;

  //       li {
  //         font-size: 2.5vw !important;

  //         &.link_one {
  //           margin-right: 2.5vw !important;
  //         }

  //         a {
  //           margin: 0px 1vw !important;
  //         }
  //       }
  //     }
  //   }
  // }

  .i_footer {

    .foot-top {
      display: flex;
       width: 95%;
       margin: auto;
      // justify-content: space-between;
    }

    background: rgb(51, 51, 51);
    // padding-top: 0.625vw;
    padding-top: 2.5vw;

    .contact-way {
      width: auto;
      flex:1;
      justify-content: center;
      .content{
       display: flex;
      }

      .contact-way-imgs {
        display: flex;
        padding: 1vw;
        width: auto;
        flex-wrap: wrap;
        .img {
          padding: 1vw;
          //margin-left: 2vw;
        }
      }

      .tel {
        width: auto;
        padding: 2vw 0;
        // margin: auto 0;
        align-items: center;
        // margin-left: 2vw;
        color: #fff;
        .tel-label {
          font-size: 1.5vw;
        }
        .tel-number {
          font-size: 1.5vw;
        }
      }

    }

    //width: 75vw;
    // margin: 0 1.8vw 0 12.5vw;
    .friend_link {
      // flex: 1;
      flex: 1;
      margin: 0 auto;
      // padding: 0.3125vw 0 0.625vw;
      padding: 1vw;
      color: #fff !important;
      .link_one {
        color: #fff !important;
        margin-right: 1.25vw;
        font-weight: bold;
        font-size: 1.2vw;
        margin-bottom: 1vw;
      }

      .link-container {
        display: flex;
        /* 使用 Flexbox 布局 */
        flex-wrap: wrap;
        /* 允许换行 */
      }

      .link-item {
        display: flex;
        /* Flexbox 用于内部元素 */
        align-items: center;
        /* 垂直居中对齐 */
        margin-right: 5px;
        /* 右侧间距 */
        margin-bottom: 10px;
        /* 底部间距 */
       flex: 0 0 calc(20% - 20px);
        /* 每行显示两个链接，减去右侧间距 */
        font-size: 0.8vw;
      }
      .link-item-text {
        display: flex;
         /* Flexbox 用于内部元素 */
         align-items: center;
         /* 垂直居中对齐 */
         margin-right: 5px;
         /* 右侧间距 */
         margin-bottom: 10px;
         /* 底部间距 */
        flex: 0 0 calc(33.33% - 20px);
         /* 每行显示两个链接，减去右侧间距 */
         font-size: 0.8vw;
      }

      .icon {
        width: 100px;
        /* 设置图标的宽度 */
        height: auto;
        /* 设置图标的高度 */
        margin-right: 5px;
        /* 图标和文本之间的间隔 */
      }

      li {
        float: left;
        font-size: 0.875vw;

        // &.link_one {
        //   color: #fff !important;
        //   margin-right: 1.25vw;
        //   font-weight: bold;
        //   font-size: 1.2vw;
        // }

        a {
          color: #ffffff !important;
          margin: 0px 0.625vw;

          &:hover {
            color: #fff !important;
            text-decoration: none;
          }
        }
      }
    }
  }
</style>
