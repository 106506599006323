<template>
  <div class="login-page">
    <TopInfo style="width: 100%" class="mobile-top" />
    <div class="login-wrapper">
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        label-position="left"
        :label-width="lang=='zhObj' ? '70px':'130px'"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('loginPage.userName')" prop="username">
          <el-input v-model="form.username" :placeholder="$t('loginPage.userNamePlaceholder')" />
        </el-form-item>
        <el-form-item :label="$t('loginPage.password')" prop="password">
          <el-input v-model="form.password" :placeholder="$t('loginPage.passwordPlaceholder')" />
        </el-form-item>
        <el-form-item :label="$t('loginPage.code')" prop="captcha">
          <el-row>
            <el-col :span="12">
              <el-input v-model="form.captcha" :placeholder="$t('loginPage.codePlaceholder')" />
            </el-col>
            <el-col :span="12">
              <button type="button" class="login-captcha" @click="changeCaptcha">
                <img v-if="captcha" :src="captcha" alt="captcha">
              </button>
            </el-col>
          </el-row>
        </el-form-item>
        <div class="operation">
          <el-button type="primary" style="width: 100%;"  class="custom-button" :disabled="loading" @click="submitForm('ruleForm')">
            {{ loading ? $t('loginPage.loging') : $t('loginPage.login') }}
          </el-button>
          <el-link class="forgetPwd" @click="showResetPwd">{{$t('loginPage.find')}}</el-link>
        </div>
      </el-form>

      <!--      </el-card>-->
    </div>
    <ResetPawd ref="resetPwd"  />
    <Bottom style="position: fixed; bottom: 3vh; left: 0; right: 0;" />
    <!--    <y-footer />-->
  </div>
</template>

<script>
import Header from '~/components/common/Header'
import YFooter from '~/components/common/Footer'
import axios from 'axios'
import config from '~/config'
import cookie from '~/utils/cookies'
import { register, trylogin, userLogin } from '../api/login'
import TopInfo from '../components/common/TopInfo.vue'
import Bottom from '../components/common/Bottom.vue'
import ResetPawd from '../components/common/resetPawd.vue'
import { getSession, getStore } from '~/utils/storage'

export default {
  name: 'Login',
  components: {
    Bottom,
    TopInfo,
    ResetPawd,
    // eslint-disable-next-line vue/no-unused-components
    Header,
    // eslint-disable-next-line vue/no-unused-components
    YFooter
  },
  data() {
    return {
      websiteInfo: this.$store.state.websiteInfo,
      lang:this.$store.state.currentLocale,
      resetPwdVisible: false,
      subState: false, // 提交状态
      loading: false,
      form: {
        username: '',
        password: '',
        captcha: '',
        key: '',
        remember: true
      },
      captcha: '',
      rules: {
        username: [
          { required: true, message: this.$t('loginPage.userNameText'), trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: this.$t('loginPage.codeText'), trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.$t('loginPage.sourceText'), trigger: 'blur' }
        ]
      }
    }
  },

  mounted() {
    this.changeCaptcha()
  },
  methods: {
    // 重置密码弹出窗
    showResetPwd() {
      // this.resetPwdVisible = true
      this.$refs.resetPwd.open()
    },
    submitForm(formName) {
      // 防止重复提交
      if (this.subState) {
        return false
      }
      const that = this
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.$nuxt.$loading.start()
          this.subState = true
          trylogin(that.form).then((res) => {
            that.subState = false
            that.$nuxt.$loading.finish()
            that.$store.commit('SET_TOKEN', `${res.data.token_type} ${res.data.access_token}`)
            // this.$store.commit('GET_TEMPORARYURL')
            that.$store.dispatch('GET_USERINFO', store => {
              that.userInfo = that.$store.state.userInfo
            })
            that.$message.success(this.$t('loginPage.loginSuc'))
            console.log('登录成功：', res)
          }).catch((err) => {
            that.subState = false
            that.$nuxt.$loading.finish()

            that.changeCaptcha()
          })
        } else {
          return false
        }
      }
      )
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    goHome() {
      const query = this.$route.query
      const path = query && query.from ? query.from : '/'
      this.$router.push(this.localePath(path)).catch(() => {
      })
    },
    changeCaptcha() {
      axios.get(config.baseUrl + '/api/system/login/captcha').then(res => {
        console.log('返回的图片信息：', res)
        if (res.data.code === 0) {
          this.captcha = res.data.data.captcha
          this.form.key = res.data.data.key
        } else {
          alert(res.data.msg)
        }
      }).catch(e => {
        alert(e.message)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .mobile-top {
    padding: 10vw 2vw;
    height: auto;
    width: 100vw;
  }
  .main-header{
    background-image:none !important;
  }
}

.box-card {
  margin: 20px;
  width: 50%;
  //background-color: rgba(255, 255, 255, 0.9);
  .clearfix {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5vw;
    color: #4685e2;
  }

  .operation {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
}

::v-deep.main-nav.el-row {
  display: none !important;
}

.login-page {
  height: 100vh;
  width: 100%;
  // display: flex;
  // justify-content: center;
  //  flex-direction: column;

}

.login-wrapper {
  padding: 0 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //background-image: url("~/assets/image/login_bg.svg");
  height: auto;
  width: 100%;
}

.login-form {
  width: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-item {
  margin-bottom: 15px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
}

.input-wrapper i {
  margin-right: 10px;
}

.input-wrapper input {
  border: none;
  outline: none;
  flex: 1;
}

.login-captcha {
  display: block;
  background: none;
  border: none;
  cursor: pointer;
}

.forgetPwd {
    margin-top: 10px;
    text-align: center;
    display: flex;
}

.login-captcha img {
  max-width: 100%;
  height: auto;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #1890ff;
  color: white;
  cursor: pointer;
}

button[type="submit"]:disabled {
  background-color: #ccc;
}

::v-deep .main-header{
  background-image:none !important;
}

.custom-button {
  background-color: #009173;
  border-color: #009173;
}

.custom-button:hover,
.custom-button:focus {
  background-color: #008065; /* 按钮悬停时颜色，可根据需要调整 */
  border-color: #008065; /* 按钮悬停时边框颜色，可根据需要调整 */
}

.custom-button:active {
  background-color: #006c54; /* 按钮按下时颜色，可根据需要调整 */
  border-color: #006c54; /* 按钮按下时边框颜色，可根据需要调整 */
}
</style>
