<template>
  <el-dialog title="重置密码" :visible.sync="dialogVisible" width="50%">
    <!-- Logo -->
    <div style="text-align: center;">
      <img
        v-if="websiteInfo"
        :src="websiteInfo[0]?.image"
        alt="陕西省服贸协会"
      >
    </div>

    <!-- 提示信息 -->
    <div style="text-align: center;margin-bottom: 4px"> 请通过输入绑定邮箱或者手机号重置你的协会帐号密码</div>

    <!-- 输入框 -->
    <el-input v-model="emailOrMobile"  placeholder="请输入邮箱或者手机号" clearable/>

    <!-- 按钮 -->
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="resetPassword">重置密码</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { resetPwdByemail } from '../../api/main'

export default {
  data() {
    return {
      dialogVisible: false,
      emailOrMobile: '',
      websiteInfo: this.$store.state.websiteInfo
    }
  },
  methods: {
    open() {
      this.dialogVisible = true
    },
    isEmailOrPhone(input) {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      const phoneRegex = /^(\+?\d{1,4}[\s\-]?)?(\d{10}|\d{3}[\s\-]\d{3}[\s\-]\d{4})$/
      if (emailRegex.test(input)) {
        return true
      } else if (phoneRegex.test(input)) {
        return true
      } else {
        return false
      }
    },
    resetPassword() {
      // 这里处理提交逻辑
      console.log('Reset password for:', this.emailOrMobile)
      // 关闭对话框
      // this.$emit('close')
      if (this.emailOrMobile.trim() === '') {
        this.$msgbox.alert('请输入邮箱或者手机号')
      } else if (!this.isEmailOrPhone(this.emailOrMobile)) {
        this.$msgbox.alert('请输入正确邮箱或者手机号')
      } else {
        resetPwdByemail(this.emailOrMobile).then(res => {
          if (res != null) {
            this.$msgbox.alert(res.data.msg)
          } else {
            this.$msgbox.alert('请前往您的邮箱查看')
            this.emailOrMobile = ''
            this.dialogVisible = false
          }
        }).catch(error => {
          this.$msgbox.alert(error)
          this.dialogVisible = false
        })
      }
    }
  }
}
</script>

<style scoped>
/* 根据需要添加样式 */
</style>
